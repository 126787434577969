.App {
  text-align: center;
  font-family: DINAlternate-Bold;
}

body {
  font-family: DINAlternate-Bold;
}

@font-face {
  font-family: DINAlternate-Bold;
  src: url(./assets/DINAlternate-Bold.ttf);
}

.defaultButton {
  background-color: '#ff373d !important';
  font-weight: 'bold !important';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #d0d2d5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}

.text-end {
  text-align: end;
}

.qrcode-sub {
  width: 234px;
  height: 110px;
  text-align: center;
  align-content: center;
  box-shadow: 4px 5px 7px lightblue;
}

.box-qrcode-sub {
  width: auto;
  height: 191px;
  text-align: center;
  align-content: center;
  box-shadow: 4px 5px 7px lightblue;
}

.dynamic-font-size {
  font-size: 1em;
  /* Tamanho padrão */
}

.dynamic-font-size.small {
  font-size: 0.9em;
  /* Tamanho pequeno */
}

.dynamic-font-size.smaller {
  font-size: 0.8em;
  /* Tamanho menor */
}

.dynamic-font-size.smallest {
  font-size: 0.7em;
  /* Tamanho ainda menor */
}

.break-word {
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.boxParticipante {
  height: auto;
  box-shadow: 4px 5px 7px lightblue;
}

.justify-center {
  justify-content: center;
}

.fez-checkin-checkbox {
  font-weight: bold;
  color: red;
  /* Exemplo de destaque */
}

.p28 {
  padding: 28px;
}

.mt30 {
  margin-top: 30px;
}

.align-self-center {
  align-self: center;
}

.item-side-menu {
  box-shadow: 3px 4px 1px #fff9f982;
  border-radius: 6px;
  text-align-last: center;
  margin-bottom: 10px;

  &:hover {
    background-color: #e95050;
  }
}

.w100 {
  width: 100%;
}

.w70 {
  width: 70%;
}

.cracha {
  /* margin-bottom: 28px; */
  padding-bottom: 28px;
  padding-top: 8px;
  font-weight: bold;
  font-size: 1em;
  text-transform: uppercase;
}

.consulta-sub {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
}

.css-11qr2p8-MuiButtonBase-root-MuiButton-root.default-button {
  background-color: #dc8a07;
  font-weight: bold;

  &:hover {
    background-color: #f33703;
  }
}

/* .css-hip9hq-MuiPaper-root-MuiAppBar-root.app-bar {
  background-color: #ffffff;
  color: #000000;
  box-shadow: 2px 2px 2px #00000015;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}